// websocketService.js
import Vue from 'vue';

const webSocketService = {
    socket: null,

    initWebSocket() {
        if (!this.socket) {
            //本地测试的websocket地址：wss://lkwyznkj.cyou:9090/ws/trajectory
            //this.socket = new WebSocket('wss://lkwyznkj.cyou:9090/ws/trajectory');

            //线上测试的websocket地址：wss://lkwyznkj.cyou:9090/ws/trajectory
            this.socket = new WebSocket('wss://lkwyznkj.cyou:9090/ws/trajectory');

            this.socket.addEventListener('open', () => {
                console.log('WebSocket 已连接(webSocketService.js)');
            });

            this.socket.addEventListener('error', (event) => {
                console.error('WebSocket 错误:', event);
            });

            this.socket.addEventListener('close', (event) => {
                console.log('WebSocket 已关闭:', event);
            });

            this.socket.addEventListener('message', (event) => {
                Vue.prototype.$bus.$emit('websocketMessage', event);
            });
        }
    },

    closeWebSocket() {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.close();
            this.socket = null; // 关闭后清空 socket 对象
        }
    },
};

export default webSocketService;
