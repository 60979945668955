// main.js
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css'
import request from '@/utils/request';
import Echarts from 'echarts';
import webSocketService from "./services/websocketService"; // 导入 WebSocket 服务

// 初始化全局事件总线
Vue.prototype.$bus = new Vue();

// 初始化 WebSocket 连接
webSocketService.initWebSocket();

Vue.config.productionTip = false

Vue.use(ElementUI, { size: "mini" });

Vue.prototype.request = request;
Vue.prototype.$request = request;
// 监听 WebSocket 消息并触发 Vuex action
Vue.prototype.$bus.$on('websocketMessage', (event) => {
  store.dispatch('handleWebSocketMessage', event);
  store.dispatch('handleWebSocketMessage1', event);
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app').$once('hook:beforeDestroy', () => {
  webSocketService.closeWebSocket();
});
