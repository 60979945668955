import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {path: '/login', name: 'Login', component: () => import('../views/Login.vue')},

  //{path: '/register', name: 'Register', component: () => import('../views/Register.vue')},

  {path: '/404', name: '404', component: () => import('../views/404.vue')},



  {path: '/guanwang', name: 'Guanwang', component: () => import('../guanwang/Guanwang.vue')},
  {path: '/lianxiwomen', name: 'Lianxiwomen', component: () => import('../guanwang/Lianxiwomen.vue')},
  {path: '/fuwuyuzhichi', name: 'Fuwuyuzhichi', component: () => import('../guanwang/Fuwuyuzhichi.vue')},
  {path: '/guanyuwomen', name: 'Guanyuwomen', component: () => import('../guanwang/Guanyuwomen.vue')},
  {path: '/uwbdingweibiaoqian', name: 'Uwbdingweibiaoqian', component: () => import('../guanwang/Uwbdingweibiaoqian.vue')},
  {path: '/uwbdingweijizhan', name: 'Uwbdingweijizhan', component: () => import('../guanwang/Uwbdingweijizhan.vue')},
  {path: '/uwbdingweixitong', name: 'Uwbdingweixitong', component: () => import('../guanwang/Uwbdingweixitong.vue')},
  {path: '/uwbdingweiruanjian', name: 'Uwbdingweiruanjian', component: () => import('../guanwang/Uwbdingweiruanjian.vue')},
  {path: '/uwbdingweizdykfpt', name: 'Uwbdingweizdykfpt', component: () => import('../guanwang/Uwbdingweizdykfpt.vue')},
  {path: '/fangbaochanpin', name: 'Fangbaochanpin', component: () => import('../guanwang/Fangbaochanpin.vue')},
  {path: '/fangkeguanli', name: 'Fangkeguanli', component: () => import('../guanwang/Fangkeguanli.vue')},
  {path: '/zhinengkaoqin', name: 'Zhinengkaoqin', component: () => import('../guanwang/Zhinengkaoqin.vue')},
  {path: '/zhinengxunjian', name: 'Zhinengxunjian', component: () => import('../guanwang/Zhinengxunjian.vue')},
  {path: '/zhinengzhizao', name: 'Zhinengzhizao', component: () => import('../guanwang/Zhinengzhizao.vue')},
  {path: '/qiyeneicangchu', name: 'Qiyeneicangchu', component: () => import('../guanwang/Qiyeneicangchu.vue')},
  {path: '/daxingchangsuo', name: 'Daxingchangsuo', component: () => import('../guanwang/Daxingchangsuo.vue')},
  {path: '/shujuzhongxin', name: 'Shujuzhongxin', component: () => import('../guanwang/Shujuzhongxin.vue')},

]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 重置路由
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    routes
  })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus");
  if (storeMenus) {
    // 拼装动态路由
    const manageRoute = { path: '/', name: 'Manage', component: () => import('../views/Manage.vue'), redirect: "/home", children: [
        { path: 'person', name: '个人中心', component: () => import('../views/Person.vue')},
        { path: 'password', name: '修改密码', component: () => import('../views/Password.vue')},
      //  { path: 'ghc', name: '公海池', component: () => import('../views/Ghc.vue')},
        { path: 'charts', name: '数据统计', component: () => import('../components/Charts.vue')},
        { path: 'trajectory', name: '实时轨迹', component: () => import('../components/Trajectory.vue')},

      ] }
    const menus = JSON.parse(storeMenus)
    menus.forEach(item => {
      if (item.path) {  // 当且仅当path不为空的时候才去设置路由
        let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue')}
        manageRoute.children.push(itemMenu)
      } else if(item.children.length) {
        item.children.forEach(item => {
          if (item.path) {
            let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue')}
            manageRoute.children.push(itemMenu)
          }
        })
      }
    })

    // 获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map(v => v.name)
    if (!currentRouteNames.includes('Manage')) {
      // 动态添加到现在的路由对象中去
      router.addRoute(manageRoute)
    }
  }
}

// 每次刷新页面都要重新设置路由，否则路由就会被重置
setRoutes()

router.beforeEach((to, from, next) => {
  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")
  if (!to.matched.length) {
    const menus = localStorage.getItem("menus")
    if (!menus) {
      next("/guanwang")
    } else {
      next("/404")
    }
  } else {
    next()
  }
})


export default router

