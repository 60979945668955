import Vue from 'vue'
import Vuex from 'vuex'
import router, {resetRouter} from "@/router";
import webSocketService from "@/services/websocketService";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentPathName: '',
    uplinkData: [],
    uwbData: [], // 存储 UWB 数据
    total: 0, // 总记录数
    pageNum: 1, // 当前页码
    pageSize: 10, // 每页显示条数
  },
  mutations: {
    setPath (state) {
      state.currentPathName = localStorage.getItem("currentPathName")
    },
    setUplinkData(state, data) {
      state.uplinkData.push(data);
    },
    setUwbData(state, data) {
      state.uwbData = data;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setPageNum(state, pageNum) {
      state.pageNum = pageNum;
    },
    setPageSize(state, pageSize) {
      state.pageSize = pageSize;
    },
    logout() {
      // 清空缓存
      localStorage.removeItem("user")
      localStorage.removeItem("menus")
      router.push("/login")
      // 重置路由
      resetRouter()
      // 关闭 WebSocket 连接
      webSocketService.closeWebSocket()
    },
    updateUwbPosition(state, data) {
      // 更新 UWB 位置信息
      const { tagID, x, y } = data;
      const rowToUpdate = state.uwbData.find(row => row.tagID === tagID);
      if (rowToUpdate) {
        rowToUpdate.x = x;
        rowToUpdate.y = y;
      }
    },
  },
  actions: {
    handleWebSocketMessage({ commit }, event) {
      const jsonData = JSON.parse(event.data);
      if (jsonData.command === 'UpLink') {
        commit('setUplinkData', jsonData);
      }
    },
    loadUwbData({ commit }, payload) {
      // 请求 UWB 数据
      const { pageNum, pageSize, tagID } = payload;
      return Vue.prototype.$request.get("/uwb/page", {
        params: {
          pageNum,
          pageSize,
          tagID,
        }
      }).then(res => {
        commit('setUwbData', res.data.records);
        commit('setTotal', res.data.total);
      });
    },
    handleWebSocketMessage1({ commit }, event) {
      const jsonData = JSON.parse(event.data);
      if (jsonData.command === 'UpLink') {
        commit('updateUwbPosition', jsonData);
      }
    },

    },
})

export default store
